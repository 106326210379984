<template>
<b-row>
  <b-col sm="12" md="6" lg="6">
    <b-card>
      <b-card-header>
        <b-card-title>{{$t('instagram.followersIncrease')}}</b-card-title>
      </b-card-header>
      <b-card-body>
        <component :is="apex_charts" class="cursor-pointer" type="line" height="300" :options="chartOptions.followers" :series="series.followers" :key="change_language"></component>
      </b-card-body>
    </b-card>
  </b-col>
  <b-col sm="12" md="6" lg="6">
    <b-card>
      <b-card-header>
        <b-card-title>{{$t('instagram.followingIncrease')}}</b-card-title>
      </b-card-header>
      <b-card-body>
        <component :is="apex_charts" class="cursor-pointer" type="line" height="300" :options="chartOptions.following" :series="series.following" :key="change_language"></component>
      </b-card-body>
    </b-card>
  </b-col>
</b-row>
</template>
<script>

import { BRow, BCol, BCard, BCardHeader, BCardTitle, BCardBody, } from 'bootstrap-vue'
import { getDate } from '@/libs/utils/times'
import { getSeries } from '@/libs/utils/series_graphs';

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
  },
  props: {
    audienceData: {
      required: true
    }
  },
  data () {
    return {
      change_language: false,
      chartOptions: {
        followers : {
          chart: {
            zoom: {
              enabled: true,
            },
            toolbar: {
              show: true,
              offsetY: -20,
              tools: {
                download: false,
                selection: false,
                zoom: '<b-icon/>',
                zoomin: false,
                zoomout: false,
                pan: false,
                reset: `<img src="${require('@/assets/images/icons/reset.png')}" width="20">`
              }
            },
            type: 'line',
            dropShadow: {
              enabled: true,
              top: 18,
              left: 2,
              blur: 5,
              opacity: 0.2,
            },
            offsetX: -10
          },
          stroke: {
            curve: 'smooth',
            width: 4,
          },
          grid: {
            borderColor: '#ebe9f1',
            padding: {
              top: -20,
              bottom: 5,
              left: 20,
            },
          },
          legend: {
            show: false,
          },
          colors: ['#df87f2'],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              inverseColors: false,
              gradientToColors: ['#6c84e0'],
              shadeIntensity: 1,
              type: 'horizontal',
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100, 100, 100],
            },
          },
          markers: {
            size: 0,
            hover: {
              size: 5,
            },
          },
          dataLabels: {
            enabled: false
          },
          xaxis: {
            labels: {
              offsetY: 5,
              style: {
                colors: '#b9b9c3',
                fontSize: '0.857rem',
              },
            },
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: false,
            },
            type: 'datetime',
            categories : this.audienceData.followers_chart.map(item => { return getDate(item.date) }).reverse()
          },
          yaxis: {
            tickAmount: 5,
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '0.857rem',
              },
              formatter(val) {
                if (val >= 1000000) return `${Math.round(val / 1000000)}M`
                if (val >= 1000) return `${Math.round(val / 1000)}K`
                return val
              },
            },
          },
        },
        following: {
          chart: {
            zoom: {
              enabled: true,
            },
            toolbar: {
              show: true,
              offsetY: -20,
              tools: {
                download: false,
                selection: false,
                zoom: '<b-icon/>',
                zoomin: false,
                zoomout: false,
                pan: false,
                reset: `<img src="${require('@/assets/images/icons/reset.png')}" width="20">`
              }
            },
            type: 'line',
            dropShadow: {
              enabled: true,
              top: 18,
              left: 2,
              blur: 5,
              opacity: 0.2,
            },
            offsetX: -10
          },
          stroke: {
            curve: 'smooth',
            width: 4,
          },
          grid: {
            borderColor: '#ebe9f1',
            padding: {
              top: -20,
              bottom: 5,
              left: 20,
            },
          },
          legend: {
            show: false,
          },
          colors: ['#df87f2'],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              inverseColors: false,
              gradientToColors: ['#6c84e0'],
              shadeIntensity: 1,
              type: 'horizontal',
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100, 100, 100],
            },
          },
          markers: {
            size: 0,
            hover: {
              size: 5,
            },
          },
          dataLabels: {
            enabled: false
          },
          xaxis: {
            type: 'datetime',
            categories : this.audienceData.following_chart.map(item => { return getDate(item.date) }).reverse()
          },
          yaxis: {
            tickAmount: 5,
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '0.857rem',
              },
              formatter(val) {
                if (val >= 1000000) return `${Math.round(val / 1000000)}M`
                if (val >= 1000) return `${Math.round(val / 1000)}K`
                return val
              },
            },
          },
        }
      },
      series: {
        followers: [
          {
            name: `${this.$t('instagram.followersTooltip')}`,
            data: getSeries(this.audienceData.followers_chart, 'count').reverse()
          }
        ],
        following: [
          {
            name: `${this.$t('instagram.followersTooltip')}`,
            data: getSeries(this.audienceData.following_chart, 'count').reverse()
          }
        ]
      },
      apex_charts: null,
    }
  },
  async mounted() {
    this.apex_charts = (await import('vue-apexcharts')).default;
  },
  watch: {
    '$i18n.locale'() {
      this.change_language = !this.change_language
      this.series.followers[0].name = `${this.$t('instagram.followersTooltip')}`;
      this.series.following[0].name = `${this.$t('instagram.followersTooltip')}`;
    }
  },
}
</script>
